export default function init() {
  const mobileNavOpenBtn = document.querySelector('.js-mobile-nav-open-btn');
  const mobileNavCloseBtn = document.querySelector('.js-mobile-nav-close-btn');
  const mobileNav = document.querySelector('.js-mobile-nav');
  const mobileNavLinks = document.querySelectorAll('.js-mobile-nav-link');
  const mobileSubNavLinks = document.querySelectorAll('.js-mobile-sub-nav-link');
  const mobileNavBackBtns = document.querySelectorAll('.js-mobile-nav-back-btn');

  // Get all nav items that have a mega menu
  const navItems = document.querySelectorAll('.js-nav-item');

  // Open the main mobile navigation
  mobileNavOpenBtn?.addEventListener('click', () => {
    mobileNav?.classList.remove('mobile-menu-close');
    mobileNav?.classList.add('mobile-menu-open');
    mobileNavOpenBtn?.classList.toggle('hidden');
    mobileNavCloseBtn?.classList.toggle('hidden');
  });

  // Close the main mobile navigation
  mobileNavCloseBtn?.addEventListener('click', () => {
    mobileNav?.classList.remove('mobile-menu-open');
    mobileNav?.classList.add('mobile-menu-close');
    mobileNavOpenBtn?.classList.toggle('hidden');
    mobileNavCloseBtn?.classList.toggle('hidden');
  });

  // Toggle sub-navigation for each main nav link
  mobileNavLinks.forEach((link) => {
    link?.addEventListener('click', () => {
      const subNav = link.nextElementSibling;
      if (subNav) {
        subNav.classList.toggle('mobile-sub-menu-open');
        subNav.classList.toggle('mobile-sub-menu-close');
      }
    });
  });

  // Ensure the sub-nav link always shows its nested items
  mobileSubNavLinks.forEach((link) => {
    link?.addEventListener('click', () => {
      const nestedSubNav = link.nextElementSibling;
      if (nestedSubNav) {
        nestedSubNav.classList.remove('mobile-sub-menu-close');
        nestedSubNav.classList.add('mobile-sub-menu-open');
      }
    });
  });

  // Hide the slide-out navigation
  mobileNavBackBtns.forEach((btn) => {
    btn?.addEventListener('click', () => {
      const slideOut = btn.closest('.js-mobile-nav-slide-out');
      if (slideOut) {
        slideOut.classList.remove('mobile-sub-menu-open');
        slideOut.classList.add('mobile-sub-menu-close');
      }
    });
  });

  // Handle mega menu for desktop navigation
  navItems.forEach((item) => {
    const link = item.querySelector('.js-nav-link');
    const menu = item.querySelector('.js-mega-menu');

    // Remove focus styles and hide mega menu
    const removeFocusStyles = () => {
      if (link instanceof HTMLElement) {
        link.classList.remove('bg-evolve-forest', 'text-white');
      }
      if (menu instanceof HTMLElement) {
        menu.style.display = 'none';
      }
    };

    // Show mega menu and add focus styles
    const addFocusStyles = () => {
      if (link instanceof HTMLElement) {
        link.classList.add('bg-evolve-forest', 'text-white');
      }
      if (menu instanceof HTMLElement) {
        menu.style.display = 'block';
      }
    };

    // Handle focus event
    link?.addEventListener('focus', () => {
      navItems.forEach((navItem) => {
        const navLink = navItem.querySelector('.js-nav-link');
        const navMenu = navItem.querySelector('.js-mega-menu');
        if (navLink instanceof HTMLElement) {
          navLink.classList.remove('bg-evolve-forest', 'text-white');
        }
        if (navMenu instanceof HTMLElement) {
          navMenu.style.display = 'none';
        }
      });
      addFocusStyles();
    });

    // Handle mouseenter event
    item.addEventListener('mouseenter', () => {
      removeFocusStyles();
      addFocusStyles();
    });

    // Check if focus has left both the link and the mega menu
    const checkFocusLost = () => {
      setTimeout(() => {
        if (!item.contains(document.activeElement)) {
          removeFocusStyles();
        }
      }, 10);
    };

    // Apply the check when the link loses focus
    link?.addEventListener('blur', checkFocusLost);

    // Ensure mega menu remains open if it's focused
    menu?.addEventListener('focusin', () => {
      if (menu instanceof HTMLElement) {
        menu.style.display = 'block';
      }
    });

    // Check if focus has left when focus leaves the mega menu
    menu?.addEventListener('focusout', checkFocusLost);

    // Handle mouseleave event for the entire nav item
    item.addEventListener('mouseleave', checkFocusLost);
  });

  const navLinks = document.querySelectorAll('.js-nav-link, .js-mobile-link');

  navLinks.forEach((link) => {
    if (link instanceof HTMLAnchorElement) {
      const currentPagePath = window.location.pathname.replace(/^\/services\//, '');
      const linkPath = link.pathname.replace(/^\/services\//, '');

      if (currentPagePath === linkPath) {
        if (link.classList.contains('js-nav-link')) {
          link.classList.add('bg-evolve-forest', 'text-white');
        } else if (link.classList.contains('js-mobile-link')) {
          link.classList.add('text-evolve-forest', 'font-extrabold');
        }
      } else {
        if (link.classList.contains('js-nav-link')) {
          link.classList.remove('bg-evolve-forest', 'text-white');
        } else if (link.classList.contains('js-mobile-link')) {
          link.classList.remove('text-evolve-forest', 'extrafont-bold');
        }
      }
    }
  });
}
