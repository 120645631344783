/**
 * Returns a promise that resolves when any of the given elements are scrolled into view.
 *
 * Usage:
 * ```ts
 * await elementsInView([el]);
 * // Do something after el is scrolled into view
 * ```
 */
export default function elementsInView(elements: HTMLElement[], distance = 0): Promise<void> {
  const rootMargin = `${distance}px 0px ${distance}px 0px`;
  return new Promise((resolve, reject) => {
    if (!elements.length) return reject(new Error('No elements to observe'));

    const observer = new IntersectionObserver(
      (entries, observer) => {
        const intersecting = entries.filter((entry) => entry.isIntersecting);
        if (!intersecting.length) return;

        intersecting.forEach((entry) => {
          observer.unobserve(entry.target);
        });
        resolve();
      },
      { rootMargin },
    );

    elements.forEach((element) => {
      observer.observe(element);
    });
  });
}

/**
 * A callback to run once when any of the given elements are scrolled into view. The
 * function's result is cached and returned on subsequent calls, like
 * [lodash.once]{@link https://lodash.com/docs/4.17.15#once}.
 *
 * May be an async function but it doesn't have to be as it's wrapped in a promise anyway.
 */
type ElementsInViewOnceCallback<T> = () => T;

/**
 * @return A function which takes some elements and runs {@link ElementsInViewOnceCallback} once when any of them
 * are scrolled into view, or any elements from subsequent calls.
 */
type ElementsInViewOnceFunc<T> = (elements: HTMLElement[]) => Promise<T>;

/**
 * Takes a callback and acts as a wrapper around {@link elementsInView}; running the given callback
 * only once. Useful for doing something once when any instance of a component is scrolled into view.
 *
 * Behaves similarly to [lodash.once]{@link https://lodash.com/docs/4.17.15#once}, but includes
 * some overhead to skip waiting for elements to enter the view if another element using the same
 * callback has already been scrolled into view.
 *
 * @param func
 * @param distance - Optional - the distance in pixels from the viewport to run func
 *
 * Usage:
 * ```ts
 * const importDepencyOnceWhenInView = elementsInViewOnce(() => import('some-package'));
 * // Or, if you need to import multiple dependencies:
 * const importDepenciesOnceWhenInView = elementsInViewOnce(() => Promise.all([
 *  () => import('some-package'),
 *  () => import('another-package'),
 * ]));
 *
 * export function init() {
 *   const el = [...document.querySelectorAll('.js-el')] as HTMLElement;
 *   if (!el.length) return;
 *   importDepenciesOnceWhenInView([el]);
 *
 *   // Or use async/await to do some something after the dependencies have been imported:
 *   (async () => {
 *     await importDepenciesOnceWhenInView([el]);
 *      // Do something after the dependencies have been imported ...
 *   )();
 * }
 * ```
 */
export function elementsInViewOnce<T>(
  func: ElementsInViewOnceCallback<T>,
  distance = 0,
): ElementsInViewOnceFunc<T> {
  let resultPromise: Promise<T> | null = null;
  let wasInView = false;
  const inViewPromises: Promise<void>[] = [];

  return async function elementsInViewOnce(elements: HTMLElement[]) {
    // Only run once
    if (resultPromise) return resultPromise;

    if (!wasInView) {
      // Register a promise for when any of these elements are scrolled into view
      inViewPromises.push(elementsInView(elements, distance));
      // Wait for a promise to resolve for any of the elements, or for any elements from subsequent calls to ElementsInViewOnceCallback
      await Promise.any(inViewPromises);
      wasInView = true;
    }
    // Bail if another element using this func has already been scrolled into view while we were
    // waiting for this one (eg. another instance of the same Vue component). Although we've waited
    // for any promise to resolve, this invocatino could still run synchronously after another
    if (resultPromise) return resultPromise;
    // Wrap the callback result in a promise and cache it to avoid running again. It doesn't matter if func is async or not as it's wrapped in a promise anyway.
    resultPromise = Promise.resolve(func());
    return resultPromise;
    // We don't need to worry about un-observing on beforeUnmount because IntersectionObserver
    // should handle garbage collection -
    // https://stackoverflow.com/questions/62638631/is-calling-intersectionobserver-unobserve-strictly-required#comment121706698_62638631
  };
}

/**
 * @module elementsInView
 *
 * To understand the problem which {@link elementsInViewOnce} solves, consider a Vue component which
 * needs to do 'foo' after any instance has been scrolled into view and done 'bar'. Eg. 'bar' could
 * be importing a package or fetching some data, and 'foo' could be something that requires
 * that package to be imported or data to be fetched. Foo only needs to be done once globally, but
 * bar needs to be done for each instance of the component after foo has completed.
 *
 * We could do something like this:
 *
 * ```ts
 * let doneBar = false;
 *
 * export default defineComponent({
 *   mounted() {
 *     this.doFoo();
 *   },
 *   methods: {
 *     async doFoo() {
 *       // Wait for bar
 *       await this.doBar();
 *       // Do foo ...
 *     },
 *     // Do something when any instance of this component is scrolled into view
 *     async doBar() {
 *       // Bail if already done
 *       if (doneBar) return;
 *       // Wait for this instance to be scrolled into view
 *       await elementsInView([this.$el as HTMLElement]);
 *       // Check again in case another instance been scrolled into view while we were waiting for this
 *       // one
 *       if (doneBar) return;
 *       // Do bar, eg. import a package or fetch some data ...
 *       doneBar = true; // Don't do it again
 *     },
 *   },
 *   // ...
 * });
 * ```
 *
 * This solves the problem but requires a lot of boilerplate. It also has the limitation of having to wait for the current instance to be scrolled into view before doing 'foo', even if another instance has already done 'bar'.
 *
 * We could use lodash.once to reduce some boilerplate:
 *
 * ```ts
 * const doBarOnce = once(async () => {
 *   // Do bar, eg. import a package or fetch some data ...
 * });
 *
 * export default defineComponent({
 *   mounted() {
 *     this.doFoo();
 *   },
 *   methods: {
 *     async doFoo() {
 *       // Wait for bar
 *       await this.doBar();
 *       // Do foo ...
 *     },
 *     async doBar() {
 *       // Wait for this instance to be scrolled into view
 *       await elementsInView([this.$el as HTMLElement]);
 *       // Only do bar once
 *       await doBarOnce(); // Don't do it again
 *     },
 *   },
 *   // ...
 * });
 * ```
 *
 * However this has the same limitations: although doBarOnce() only runs once, doBar() still won't resolve until the current instance has been scrolled into view, regardless of if another instance has already done bar.
 *
 * ```ts
 * const doBarOnceWhenInView = elementsInViewOnce(async () => {
 *   // Do bar, eg. import a package or fetch some data ...
 * });
 *
 * export default defineComponent({
 *   mounted() {
 *     this.doFoo();
 *   },
 *   methods: {
 *     async doFoo() {
 *       // Wait for bar
 *       await doBarOnceWhenInView([this.$el as HTMLElement]);
 *       // Do foo ...
 *     },
 *   },
 *   // ...
 * });
 * ```
 *
 * Now doBarOnceWhenInView() will not only only run once globally, it will also resolve immediately if another instance has already done bar.
 *
 * @question What if another instance resolves bar while this one is waiting to be resolved? It should resolve immediately then too?
 */
