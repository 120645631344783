import elementsInView from '@helpers/elementsInView';

type Module<T extends HTMLElement> = { default: (elements: T[]) => void };

type Importer<T extends HTMLElement> = () => Promise<Module<T>>;

type ImporterMap<T extends HTMLElement[]> = {
  [K in keyof T]: { selector: string; importer: Importer<T[K]>; distance?: number };
};

export async function loadModuleWhenInView<T extends HTMLElement>(
  selector: string,
  importer: Importer<T>,
  distance = 0,
) {
  const elements = [...document.querySelectorAll<T>(selector)];
  if (!elements.length) return;

  await elementsInView(elements, distance);
  const module = await importer();
  module.default(elements);
}

export async function loadModulesWhenInView<T extends HTMLElement[]>(importers: ImporterMap<T>) {
  return Promise.all([
    ...importers.map(({ selector, importer, distance }) =>
      loadModuleWhenInView(selector, importer, distance),
    ),
  ]);
}
