/**
 * This module handles scrolling to anchor links where dynamic imports of modules causes layout shift,
 * meaning the anchor link positions are not fixed.
 */
import { elementsInViewOnce } from '@helpers/elementsInView';

type ScrollOptions = {
  top: number;
  behavior: 'smooth' | 'auto';
};

type MutationObserverConfig = {
  attributes: boolean;
  childList: boolean;
  subtree: boolean;
};

type AnchorScrollHandler = () => void;

type InitAnchorLinkScrollFunction = (elements: HTMLElement[]) => void;

const SCROLL_OFFSET = 30;

/**
 * Scroll to the anchor target element
 * @param targetElement - The anchor target element to scroll to
 */
const scrollToAnchor = (targetElement: HTMLElement): void => {
  const targetPosition: number = targetElement.getBoundingClientRect().top + window.scrollY;
  const scrollOptions: ScrollOptions = {
    top: targetPosition - SCROLL_OFFSET,
    behavior: 'smooth',
  };
  window.scrollTo(scrollOptions);
};

/**
 * Handle the anchor scroll when the page loads or the hash changes.
 * Use a MutationObserver to watch for changes to the document and scroll to the anchor target  element.
 * Stop observing after 5 seconds.
 */
const handleAnchorScroll: AnchorScrollHandler = () => {
  if (window.location.hash) {
    const targetId: string = window.location.hash.substring(1);
    const targetElement: HTMLElement | null = document.getElementById(targetId);

    if (targetElement) {
      // Initial scroll attempt
      scrollToAnchor(targetElement);

      // Set up a MutationObserver to watch for changes
      const observer: MutationObserver = new MutationObserver(() => {
        scrollToAnchor(targetElement);
      });

      // Configure the observer
      const config: MutationObserverConfig = { attributes: true, childList: true, subtree: true };

      // Start observing the document body
      observer.observe(document.body, config);

      // Stop observing after a set time (e.g., 5 seconds)
      setTimeout(() => {
        observer.disconnect();
      }, 5000);
    }
  }
};

/**
 * Initialize the anchor link scroll module.
 * Run handleAnchorScroll immediately if there's a hash in the URL.
 * Add an event listener for future hash changes.
 */
const initAnchorLinkScroll: InitAnchorLinkScrollFunction = elementsInViewOnce(() => {
  if (window.location.hash) {
    handleAnchorScroll();
  }

  window.addEventListener('hashchange', handleAnchorScroll);
});

export default async function init(): Promise<void> {
  const anchorTargets: NodeListOf<Element> = document.querySelectorAll('[id^="blockIdAnchor-"]');
  if (anchorTargets.length) {
    initAnchorLinkScroll(Array.from(anchorTargets) as HTMLElement[]);
  }
}
