// This module provides a means to listen to media query change events. It replicates the
// same breakpoint logic as the corresponding SCSS mixin; using rems instead of pixels.

export const breakpoints = {
  xs: 425,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1920,
};
type Breakpoint = keyof typeof breakpoints;

/**
 * @param breakpoint
 * @param useMax Whether to use a max-width query rather the default min-width
 */
export default function getMedia(breakpoint: Breakpoint, useMax = false) {
  const query = `only screen and (${
    useMax ? `max-width: ${breakpoints[breakpoint] - 1}` : `min-width: ${breakpoints[breakpoint]}`
  }px)`;
  return window.matchMedia(query);
}

export async function mediaMatches(breakpoint: Breakpoint, useMax = false) {
  return new Promise<void>((resolve) => {
    const media = getMedia(breakpoint, useMax);
    if (media.matches) return resolve();

    const listener = () => {
      if (media.matches) {
        media.removeEventListener
          ? media.removeEventListener('change', listener)
          : media.removeListener(listener);
        resolve();
      }
    };

    media.addEventListener
      ? media.addEventListener('change', listener)
      : media.addListener(listener);
    return getMedia(breakpoint, useMax).matches;
  });
}

type MediaMatchesOnceCallback<T> = () => T;

type MediaMatchesOnceFunc<T> = (elements: HTMLElement[]) => Promise<T>;

export function mediaMatchesOnce<T>(
  func: MediaMatchesOnceCallback<T>,
  breakpoint: Breakpoint,
  useMax = false,
): MediaMatchesOnceFunc<T> {
  let resultPromise: Promise<T> | null = null;
  const mediaMatchesPromise = mediaMatches(breakpoint, useMax);

  return async function mediaMatchesOnce() {
    // Only run once
    if (resultPromise) return resultPromise;

    await mediaMatchesPromise;

    if (resultPromise) return resultPromise;

    resultPromise = Promise.resolve(func());
    return resultPromise;
  };
}
