import fetchData from '@helpers/fetchData';
import { elementsInViewOnce } from '@helpers/elementsInView';

let mapboxglPromise: Promise<never> | PromiseLike<{ default: never }> | { default: never };
const accessToken =
  'pk.eyJ1IjoiZXZvbHZlcHN5Y2hvbG9neSIsImEiOiJjbHRwcTFxb3Ewcm9mMmpxcDR4dHZqaHloIn0.zHFfmYKSe-hlcxCdhvE9Qg';
const mapboxStyle = 'mapbox://styles/evolvepsychology/clxx8usoi00fp01paakeugd74';
const markerColour = '#6E2050';

/**
 * Import mapbox-gl dependencies once when the map is in view with a 250px buffer
 */
const importDepenciesOnceWhenInView = elementsInViewOnce(() => {
  mapboxglPromise = Promise.all([
    import('mapbox-gl'), // Store the promise
    import('mapbox-gl/dist/mapbox-gl.css'), // Store the promise
  ]);

  return mapboxglPromise;
}, 250);

/**
 * Get the map container
 */
const getMapContainer = () => document.querySelector<HTMLElement>('.js-map');

/**
 * Get the location from the map container
 * @param container
 */

const getLocation = (container: HTMLElement | null) => {
  if (!container) return;
  const placeName = container.dataset.location;
  const lng = container.hasAttribute('data-lng') ? container.dataset.lng : undefined;
  const lat = container.hasAttribute('data-lat') ? container.dataset.lat : undefined;
  const lnglat = lng && lat ? [+lng, +lat] : undefined;
  const location = lnglat || placeName;
  return location;
};

/**
 * Get the zoom level from the map container
 * @param container
 */
const getZoomLevel = (container: HTMLElement | null) =>
  parseInt(<string>container?.dataset.zoomlevel) ?? 12;

/**
 * Initialize the map
 * @param container
 * @param location
 * @param zoom
 */

const initializeMap = async (container: HTMLElement, location: object | string, zoom: number) => {
  try {
    const [{ default: mapboxgl }] = await mapboxglPromise;
    const geoData = await fetchGeoData(location);
    if (!geoData || !geoData.features || !geoData.query) {
      throw new Error('Invalid geodata received');
    }

    const coords = typeof location === 'object' ? geoData.query : geoData.features[0].center;
    mapboxgl.accessToken = accessToken;
    const map = new mapboxgl.Map({
      container: container,
      style: mapboxStyle,
      center: coords,
      zoom: zoom,
      scrollZoom: false,
    });
    map.addControl(new mapboxgl.NavigationControl());

    new mapboxgl.Marker({ color: markerColour }).setLngLat(coords).addTo(map);

    window.addEventListener('resize', () => map.resize());
  } catch (error: never) {
    console.error(`Map initialization error: ${error.message}`);
  }
};

/**
 * Fetch geodata from Mapbox
 * @param location
 */
const fetchGeoData = async (location: object | string) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?access_token=${accessToken}`;
  return fetchData(url).catch((error) => {
    console.error(`Error fetching geodata: ${error.message}`);
    throw error; // Re-throw the error to be caught in initializeMap
  });
};

export default async function init() {
  const mapContainer = getMapContainer();
  const location = getLocation(mapContainer);
  const zoom = getZoomLevel(mapContainer);

  if (!mapContainer || !location) {
    return;
  }

  await importDepenciesOnceWhenInView([mapContainer]);
  await initializeMap(mapContainer, location, zoom);
}
